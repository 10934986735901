import React from 'react';
import { Box, Alert } from '@mui/material/';

export default function Error({ message }) {

    return (
        <Box>
            <Alert severity='error'>{message}</Alert>
        </Box>
    )
}

