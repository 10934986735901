import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import Utility from './Utils';

const HeatConditionTable = () => {
  const data = Utility.getHighHeatConditions();

  return (
    <div className='TableHeader'>
      <Typography variant='h5' align='center' gutterBottom>Heat Conditions</Typography>
      <TableContainer component={Paper} sx={{ overflowX: 'auto' }}  tabIndex='0'>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '1.2rem', width: '10%' }}>Heat Conditions</TableCell>
              <TableCell sx={{ fontSize: '1.2rem', width: '25%' }}>Immediate Required Actions</TableCell>
              <TableCell sx={{ fontSize: '1.2rem', width: '25%' }}>Situational Awareness Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((levelObj, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: '1.1rem', padding: '0px 0px 0px 10px' }}>
                  {levelObj.level}
                </TableCell>
                <TableCell sx={{ fontSize: '1.1rem', padding: '0px 0px 0px 10px' }}>
                  <Typography component='div' sx={{ fontSize: '1.1rem' }}>
                    <ul style={{ padding: '10px', listStyleType: 'disc', margin: 0 }}>
                      {levelObj.immediateRequiredActions.map((action, idx) => (
                        <li key={idx}>{action}</li>
                      ))}
                    </ul>
                  </Typography>
                </TableCell>
                <TableCell sx={{ fontSize: '1.1rem', padding: '0px 0px 0px 10px' }}>
                  <Typography component='div' sx={{ fontSize: '1.1rem' }}>
                    <ul style={{ padding: '10px', listStyleType: 'disc', margin: 0 }}>
                      {levelObj.situationalAwarenessActions.map((action, idx) => (
                        <li key={idx}>{action}</li>
                      ))}
                    </ul>
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HeatConditionTable;
