import * as React from 'react';
import { Tab, Tabs, Typography } from '@mui/material';

export default function ColorTabs({ onTabChange }) {
  const [value, setValue] = React.useState('upc');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label='Campus Tabs'
      TabIndicatorProps={{ sx: { backgroundColor: '#FFCC00', transition:'none' } }}
      sx={{
        '& button': { color: 'black' },
        '& button:focus': { backgroundColor: '#990000', color: 'white' },
        '& button.Mui-selected': { backgroundColor: '#990000', color: 'white' },
        paddingBottom: '15px'
      }}
    >
      <Tab value='upc' label={<Typography variant='h6'>UPC</Typography>} tabIndex={0} />
      <Tab value='hsc' label={<Typography variant='h6'>HSC</Typography>} tabIndex={-1} />
    </Tabs>
  );
}
