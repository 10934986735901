import * as React from 'react';
import {Box} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme =  createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FFCC00',
      contrastText: '#FFCC00',
    }
  },
});

const Loading = ()=> {
  return (
    <Box>
      <ThemeProvider theme={theme}>
      <LinearProgress />
      </ThemeProvider>
    </Box>
  );
}

export default Loading;