import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import Utility from './Utils';

const AirQualityConditionsTable = () => {
  const data = Utility.getAQIInformation();
  return (
    <div className='TableHeader'>
      <Typography variant='h5' align='center' gutterBottom>Air Quality Conditions</Typography>
      <TableContainer component={Paper}  sx={{ overflowX: 'auto' }}  tabIndex='0'>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '1.2rem', width: '10%'}}>AQI Level</TableCell>
              <TableCell sx={{ fontSize: '1.2rem', width: '25%'}}>Immediate Required Actions</TableCell>
              <TableCell sx={{ fontSize: '1.2rem', width: '25%' }}>Situational Awareness Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(levelObj => (
              <TableRow key={Object.keys(levelObj)[0]}>
                <TableCell sx={{ fontSize: '1.1rem', padding: '0px 0px 0px 10px' }}>
                  {levelObj[Object.keys(levelObj)[0]].AQI}
                </TableCell>
                <TableCell sx={{ fontSize: '1.1rem', padding: '0px 0px 0px 10px' }}>
                  <Typography component='div' sx={{ fontSize: '1.1rem' }}>
                    <ul style={{ padding: '10px', listStyleType: 'disc', margin: 0 }}>
                      {levelObj[Object.keys(levelObj)[0]].Immediate_Required_Actions.map((action, index) => (
                        <li key={`${Object.keys(levelObj)[0]}-action-${index}`}>{action}</li>
                      ))}
                    </ul>
                  </Typography>
                </TableCell>
                <TableCell sx={{ fontSize: '1.1rem', padding: '0px 0px 0px 10px' }}>
                  {levelObj[Object.keys(levelObj)[0]].Situational_Awareness_Actions}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AirQualityConditionsTable;
