import React from 'react';
import GetHourlyWeather from './GetHourlyForecast';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Loading from './Loading';
import Error from './Error';

const HourlyForecast = ({ campus }) => {
    const images = require.context('./Assets/Icons', true);
    const formatDate = (unixTimestamp) => {
        const date = new Date(unixTimestamp * 1000);
        const hours = date.getHours() % 12 || 12; // Convert hours to 12-hour format
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${hours} ${ampm}`;
    };

    const { data: hourlyWeatherData, isLoading, isError } = GetHourlyWeather(campus);
    if (isLoading) return <Loading />;

    if (isError) return <Error message={'An error occurred loading the hourly forecast'}/>;

    return (
        <Box
            display='flex'
            flexWrap='nowrap'
            sx={{ overflowX: 'scroll' }}
            role='region'
            aria-label='Hourly Forecast'
            tabIndex='0'
        >
            {hourlyWeatherData &&
                hourlyWeatherData.hourly &&
                hourlyWeatherData.hourly.time.map((timestamp, index) => (
                    <Card
                        sx={{
                            width: { xs: '49%', sm: '32%', flexShrink: 0, marginBottom: 5 },
                            mt: 1
                        }}
                        key={index}
                    >
                        <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                flexWrap='wrap'
                            >
                                <Box sx={{ width: '40%', textAlign: 'left' }}>
                                    <img
                                        alt={`Weather Icon ${index}`}
                                        src={images(`./${hourlyWeatherData.hourly.is_day[index]}${hourlyWeatherData.hourly.weather_code[index]}.svg`)}
                                        width='100%'
                                        sx={{ objectFit: 'cover' }}
                                    />
                                </Box>
                                <Box sx={{ width: '60%' }}>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.4rem' },
                                            fontWeight: '500',
                                        }}
                                    >
                                        {formatDate(hourlyWeatherData.hourly.time[index])}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.4rem' },
                                            fontWeight: '500',
                                        }}
                                    >
                                        {Math.round(hourlyWeatherData.hourly.temperature_2m[index])}°F
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    );
};

export default HourlyForecast;
