import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Forecast from './Forecast';
import CurrentWeather from './CurrentWeather';

const Weather = ({ campus }) => {

    return (
        <Box className='GridItem' display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <Typography component='h2' variant='h5' align='left' sx={{ marginTop: '1rem' }}>
                {campus.toUpperCase()} Current Weather
            </Typography>
            <Grid container flexGrow={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <CurrentWeather campus={campus} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Forecast campus={campus} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default Weather;
