import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Utility from './Utils';
import GetCurrentAQI from './GetCurrentAQI';
import GetCurrentWeather from './GetCurrentWeather';
import Loading from './Loading';
import Error from './Error';

const CurrentConditionsActions = ({ campus }) => {
  const { isLoading: currentAQILoading, error: currentAQIError, data: currentAQI } = GetCurrentAQI(campus);
  const { isLoading: currentWeatherLoading, error: currentWeatherError, data: currentWeather } = GetCurrentWeather(campus);

  let aqiActions = [];
  let heatActions = [];

  if (!currentAQILoading && !currentAQIError && currentAQI) {
    const aqiValue = Utility.calulateAqi(currentAQI.sensor['pm2.5']);
    aqiActions = Utility.getImmediateAqiActions(aqiValue);
  }

  if (!currentWeatherLoading && !currentWeatherError && currentWeather) {
    const temperature = Math.round(currentWeather.current.temperature_2m);
    heatActions = Utility.getImmediateHeatActions(temperature);
  }

  return (
    <Box className="GridItem" display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Typography component="h2" variant="h5" align="left" sx={{ marginTop: '1rem' }}>
        Current Actions
      </Typography>
      <Grid container rowSpacing={2} role="region" aria-labelledby="current-actions-heading">
        <Grid item xs={12} md={12} role="region" aria-labelledby="aqi-actions">
          {currentAQILoading && <Loading />}
          {currentAQIError && <Error message="An error occurred loading Current Actions for Air Quality" />}
          {!currentAQILoading && !currentAQIError && (
            <Box sx={{ p: 2, border: '1px solid grey', borderRadius: '4px', height: '11rem', overflowY: 'scroll', overflow: 'auto' }} role="region" aria-labelledby="aqi-conditions">
              <Typography id="aqi-actions" variant="h6" align="left">
                AQI Immediate Actions
              </Typography>
              <div id="aqi-conditions">
                <ul style={{ listStyleType: 'disc', margin: 0, textAlign: 'left' }}>
                  {aqiActions.map((action, idx) => (
                    <li key={idx}>{action}</li>
                  ))}
                </ul>
              </div>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={12} role="region" aria-labelledby="heat-actions-heading">
          {currentWeatherLoading && <Loading />}
          {currentWeatherError && <Error message="An error occurred loading Current Actions for Heat Conditions" />}
          {!currentWeatherLoading && !currentWeatherError && (
            <Box sx={{ p: 2, border: '1px solid grey', borderRadius: '4px', height: '11rem', overflowY: 'scroll', overflow: 'auto' }} role="region" aria-labelledby="heat-conditions">
              <Typography id="heat-actions-heading" variant="h6" align="left">
                Heat Immediate Actions
              </Typography>
              <div id="heat-conditions">
                <ul style={{ listStyleType: 'disc', margin: 0, textAlign: 'left' }}>
                  {heatActions.map((action, idx) => (
                    <li key={idx}>{action}</li>
                  ))}
                </ul>
              </div>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CurrentConditionsActions;
