import { useQuery } from '@tanstack/react-query';
import config from './config.json';

const GetHourlyWeather = (campus) => {
    const getHourlyForecast = async () => {
        // eslint-disable-next-line no-template-curly-in-string
        const apiUrl = config.weatherurls.hours.url.replace('${campus}', campus);
        const result = await fetch(apiUrl);
        return result.json();
    }

  return useQuery({
    queryKey: ['hourlyforecast', campus],
    queryFn: getHourlyForecast,
    refetchInterval: 15 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });
};

export default GetHourlyWeather;
