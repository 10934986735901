import { useQuery } from '@tanstack/react-query';
import config from './config.json';

const GetCurrentWeather = (campus) => {
  const getCurrentWeather = async () => {
    // eslint-disable-next-line no-template-curly-in-string
    const apiUrl = config.current.currentweather.url.replace('${campus}', campus.toLowerCase());
    const result = await fetch(apiUrl);
    return result.json();
  };

  return useQuery({
    queryKey: ['currentforecast', campus],
    queryFn: getCurrentWeather,
    refetchInterval: 5 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });
};

export default GetCurrentWeather;
