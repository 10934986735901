import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import Utility from './Utils';
import config from './config.json';
import Loading from './Loading';
import Error from './Error';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#990000',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      disabled: '#767676',
      primary: '#000000',
      secondary: '#000000',
    },
  },
});

const LineChartWeather = ({ campus }) => {
  const [selectedRange, setSelectedRange] = useState('Upcoming7Days');

  const { data: chartData, isLoading, error } = useQuery({
    queryKey: ['lineChartWeather', selectedRange, campus],
    queryFn: async () => {
      const urls = config.weatherurls;
      // eslint-disable-next-line no-template-curly-in-string
      const response = await fetch(urls[selectedRange].url.replace('${campus}', campus));
      const data = await response.json();
      return data.daily.time.map((timestamp, index) => ({
        time: Utility.formatChartDataTime(timestamp * 1000, 'date'),
        maxTemp: data.daily.temperature_2m_max[index],
        minTemp: data.daily.temperature_2m_min[index],
      }));
    },
    refetchInterval: 15 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });

  const handleRadioChange = (event) => {
    setSelectedRange(event.target.value);
  };

  if (isLoading) return <Loading />;

  if (error) return <Error message='An error occurred loading the weather chart' />;

  return (
    <div className='GridItem' role='region' aria-label='Weather Chart'>
      <Typography component='h2' variant='h5' align='left'>
        Temperature
      </Typography>
      <ResponsiveContainer aspect={2.0}>
        <LineChart data={chartData} style={{ marginTop: '2rem' }}>
          <Line type='natural' dataKey='maxTemp' stroke='#990000' name='Max Temperature' activeDot={{ r: 10 }} strokeWidth={3} />
          <Line type='natural' dataKey='minTemp' stroke='#FFCC00' name='Min Temperature' activeDot={{ r: 10 }} strokeWidth={3} />
          <XAxis dataKey='time' stroke='#000000' tickSize={7} scale='auto' fontSize={15} padding={{ right: 3 }} />
          <YAxis stroke='#000000' tickSize={8} padding={{ top: 7 }} />
          <Legend iconSize={16} wrapperStyle={{ fontSize: '20px' }} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
      <FormControl component='fieldset' sx={{ marginTop: '1rem' }}>
        <ThemeProvider theme={theme}>
          <RadioGroup row aria-label='time-range' name='time-range' value={selectedRange} onChange={handleRadioChange}>
            <FormControlLabel value='Upcoming7Days' control={<Radio />} label='Upcoming 7 Days' />
            <FormControlLabel value='Past7Days' control={<Radio />} label='Past 7 Days' />
            <FormControlLabel value='Past30Days' control={<Radio />} label='Past 30 Days' />
          </RadioGroup>
        </ThemeProvider>
      </FormControl>
    </div>
  );
};

export default LineChartWeather;
