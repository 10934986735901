import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <div>
    <Box
      component='footer'
      sx={{
        backgroundColor: '#44433C',
        color: '#FFFFFF',
        padding: '10px 0',
        mt: 4,
      }}
    >
      <Container
        maxWidth={false} // Allow the container to take full width
        sx={{
          px: 1,
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{
            justifyContent: 'space-around',
          }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant='h6' gutterBottom>
              RELATED WEBSITES
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <Link href='https://dps.usc.edu/' color='inherit' underline='always'>
                  Department of Public Safety
                </Link>
              </li>
              <li>
                <Link href='https://fsep.usc.edu/' color='inherit' underline='always'>
                  Fire Safety and Emergency Planning
                </Link>
              </li>
              <li>
                <Link href='https://green.usc.edu/' color='inherit' underline='always'>
                  Sustainability
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' gutterBottom>
              EMERGENCY AND SAFETY INFO
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <Link href='https://sites.usc.edu/ehs/occhealth/injury-prevention/incident-reporting/' color='inherit' underline='always'>
                  Report an Incident
                </Link>
              </li>
              <li>
                <Link href='https://sites.usc.edu/ehs/seeking-medical-treatment/' color='inherit' underline='always'>
                  Seek Medical Treatment
                </Link>
              </li>
              <li>
                <Link href='https://sites.usc.edu/ehs/report-a-safety-concern-form/' color='inherit' underline='always'>
                  Submit a Safety Concern
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant='h6' gutterBottom>
              SYSTEMS LOGIN
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <Link href='https://adminsafety.usc.edu/ehsaweb/' color='inherit' underline='always'>
                  EHS Assistant
                </Link>
              </li>
              <li>
                <Link href='http://trojanlearn.usc.edu/' color='inherit' underline='always'>
                  TrojanLearn
                </Link>
              </li>
              <li>
                <Link href='https://istar.usc.edu/' color='inherit' underline='always'>
                  iStar
                </Link>
              </li>
              <li>
                <Link href='https://sites.usc.edu/ehs/research/lab/sds/' color='inherit' underline='always'>
                  Safety Data Sheets
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' gutterBottom>
              ENVIRONMENTAL HEALTH AND SAFETY
            </Typography>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              <li>
                <Link href='https://sites.usc.edu/ehs/about/' color='inherit' underline='always'>
                  About Us
                </Link>
              </li>
              <li>
                <Link href='https://sites.usc.edu/ehs/contact/' color='inherit' underline='always'>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link href='https://sites.usc.edu/ehs/staff-directory-2/' color='inherit' underline='always'>
                  Staff Directory
                </Link>
              </li>
              <li>
                <Link href='https://sites.usc.edu/ehs/fact-sheets/' color='inherit' underline='always'>
                  Fact/Guide Sheets and Health Alerts
                </Link>
              </li>
              <li>
                <Link href='https://accessibility.usc.edu/accessibility-at-usc/digital-accessibility' color='inherit' underline='always'>
                  Digital Accessibility
                </Link>
              </li>
            </ul>
          </Grid>
        </Grid>
        <Box
          textAlign='center'
          mt={3}
          sx={{
            display: 'inline-flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant='body2' sx={{ mx: 1 }}>
            © {new Date().getFullYear()} University of Southern California
          </Typography>
          <Link href='https://www.usc.edu/privacy-notice' color='inherit' underline='always' sx={{ mx: 1 }}>
            Privacy Notice
          </Link>
          <Link href='https://eeotix.usc.edu/notice-of-non-discrimination/' color='inherit' underline='always' sx={{ mx: 1 }}>
            Notice of Non-Discrimination
          </Link>
          <Link href='https://accessibility.usc.edu/accessibility-at-usc/digital-accessibility/' color='inherit' underline='always' sx={{ mx: 1 }}>
            Digital Accessibility
          </Link>
        </Box>
      </Container>
    </Box>
    </div>
  );
};

export default Footer;
