import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const CurrentWeatherCard = (props) => {
  const { attributeName, attributeValue, attributeUnit, attributeUrl } = props;

  return (    
    <Card
      sx={{
        width: { xs: '49%', sm: '32%' },
        mt: 1
      }}
    >
      <CardContent sx={{ p:0, '&:last-child': { pb: 0 }}}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexWrap='wrap'
        >
          <Box sx={{ width: '40%', textAlign: 'left' }}>
            <img
              alt='weather-icon'
              src={attributeUrl}
              width='100%'
              sx={{ objectFit: 'cover' }}
            />
          </Box>
          <Box sx={{ width: '60%' }}>
            <Typography
              sx={{
                fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.4rem' }
              }}
            >
              {attributeName}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.4rem' }
              }}
            >
              {attributeValue} {attributeUnit}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CurrentWeatherCard;