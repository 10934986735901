import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import Utility from './Utils';
import config from './config.json';
import Loading from './Loading';
import Error from './Error';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#990000',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      disabled: '#767676',
      primary: '#000000',
      secondary: '#000000',
    },
  },
});

const LineChartAqi = ({ campus }) => {
  const [selectedRange, setSelectedRange] = useState('12hr');

  const { data: chartData, isLoading, error } = useQuery({
    queryKey: ['lineChartAqi', selectedRange, campus],
    queryFn: async () => {
      const urls = config.aqiurls;
      // eslint-disable-next-line no-template-curly-in-string
      const response = await fetch(urls[selectedRange].url.replace('${campus}', campus));
      const data = await response.json();
      const formattedData = data.data
        .sort((a, b) => a[0] - b[0]) // Sort by epoch time
        .map((item) => ({
          time: Utility.formatChartDataTime(item[0] * 1000, urls[selectedRange].format),
          AQI: Utility.calulateAqi(item[1]),
        }));

      return formattedData;
    },
    refetchInterval: 15 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });

  const handleRadioChange = (event) => {
    setSelectedRange(event.target.value);
  };

  if (isLoading) return <Loading />;

  if (error) return <Error message='An error occurred loading the AQI chart' />;

  return (
    <div className='GridItem' role='region' aria-label='AQI Chart'>
      <Typography component='h2' variant='h5' align='left'>
        Historical Campus Air Quality
      </Typography>
      <ResponsiveContainer aspect={2.0}>
        <LineChart data={chartData} style={{ marginTop: '2rem' }}>
          <Line type='monotone' dataKey='AQI' stroke='#990000' activeDot={{ r: 10 }} strokeWidth={3} />
          <XAxis dataKey='time' stroke='#000000' tickSize={7} scale='auto' fontSize={15} padding={{ right: 3 }} />
          <YAxis stroke='#000000' tickSize={8} padding={{ top: 7 }} />
          <Legend iconType='circle' iconSize={16} wrapperStyle={{ fontSize: '20px' }} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
      <FormControl component='fieldset' sx={{ marginTop: '1rem' }}>
        <ThemeProvider theme={theme}>
          <RadioGroup row aria-label='time-range' name='time-range' value={selectedRange} onChange={handleRadioChange}>
            <FormControlLabel value='12hr' control={<Radio />} label='12 Hours' />
            <FormControlLabel value='7days' control={<Radio />} label='7 Days' />
            <FormControlLabel value='30days' control={<Radio />} label='30 Days' />
          </RadioGroup>
        </ThemeProvider>
      </FormControl>
    </div>
  );
};

export default LineChartAqi;
