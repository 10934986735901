import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Circle, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box } from '@mui/material';
import Utils from './Utils';
import GetCurrentAQI from './GetCurrentAQI';
import Error from './Error';

const USCMap = ({ campus }) => {
  const mapRef = useRef(null);
  const [popupOpen, setPopupOpen] = useState(false); // State variable to track popup open/close

  useEffect(() => {
    const handleCampusChange = () => {
      const map = mapRef.current;
      if (map) {
        map.setView(Utils.getCampusCoordinates(campus), 16);
      }
    };

    handleCampusChange();
  }, [campus]);

  const { isLoading, error, data } = GetCurrentAQI(campus);

  if (isLoading) return 'Loading...';

  if (error) return <Error message={'An error occurred loading the map'}/>;

  const handleCircleClick = () => {
    setPopupOpen(!popupOpen); // Toggle popup state
  };

  return (
    <Box className='GridItem' display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <MapContainer ref={mapRef} center={Utils.getCampusCoordinates(campus)} zoom={16} style={{ height: '500px', width: '100%' }} doubleClickZoom={false}
        closePopupOnClick={false} dragging={false} zoomSnap={false} zoomDelta={false} trackResize={false} touchZoom={false} scrollWheelZoom={false} zoomControl={false}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Circle
          center={Utils.getCampusCoordinates(campus)}
          radius={300}
          pathOptions={{ fillColor: Utils.getAQIColorIndex(data.sensor['pm2.5']), fillOpacity: 0.4 }}
          eventHandlers={{
            click: handleCircleClick, // Call handleCircleClick when the circle is clicked
          }}
        />
        {/* Render popup if popupOpen is true */}
        {popupOpen && (
          <Popup position={Utils.getCampusCoordinates(campus)} onClose={() => setPopupOpen(false)}>
            <div>
            <h3>{`AQI: ${Utils.calulateAqi(data.sensor['pm2.5'])}`}</h3>
              <h3>{`PM2.5: ${data.sensor['pm2.5']} `}</h3>
            </div>
          </Popup>
        )}
      </MapContainer>
    </Box>
  );
};

export default USCMap;
