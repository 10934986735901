import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import config from './config.json';
import Loading from './Loading';
import Error from './Error';

const DailyForecast = ({ campus }) => {
  const images = require.context('./Assets/Icons', true);
  
  const getDailyForecast = async () => {
    // eslint-disable-next-line no-template-curly-in-string
    const apiUrl = config.weatherurls.Upcoming7Days.url.replace('${campus}', campus);
    const result = await fetch(apiUrl);
    return result.json();
  }

  const { data: upcomingWeatherData, isLoading, isError } = useQuery({
    queryKey: ['dailyforecast', campus],
    queryFn: getDailyForecast,
    refetchInterval: 15 * 60 * 1000,
    staleTime: 15 * 60 * 1000,
  });

  if (isLoading) return <Loading />;
  if (isError) return <Error message='An error occurred loading the daily forecast' />;

  const formatUnixTimestamp = (timestamp) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(timestamp * 1000);
    return days[date.getDay()];
  };

  return (
    <Box
      display='flex' tabIndex='0'
      flexWrap='nowrap'
      sx={{ overflowX: 'scroll'}}
      role='region'
      aria-label='Daily Forecast'
    >
      {upcomingWeatherData &&
        upcomingWeatherData.daily &&
        upcomingWeatherData.daily.time.map((timestamp, index) => (
          <Card
            key={index}
            sx={{
              width: { xs: '49%', sm: '32%', flexShrink: 0, marginBottom: 5 },
              mt: 1,
            }}
            tabIndex={0}
          >
            <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexWrap='wrap'
                role='group'
                aria-label='Daily Forecast Item'
              >
                <Box sx={{ width: '40%', textAlign: 'left' }}>
                  <img
                    alt='weather-icon'
                    src={images(`./${upcomingWeatherData.daily.weather_code[index]}.svg`)}
                    width='100%'
                    sx={{ objectFit: 'cover' }}
                  />
                </Box>
                <Box sx={{ width: '60%' }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.4rem' },
                      fontWeight: '500',
                    }}
                  >
                    {formatUnixTimestamp(timestamp)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '1.1rem', sm: '1.1rem', md: '1.4rem' },
                    }}
                  >
                    {Math.round(upcomingWeatherData.daily.temperature_2m_max[index])}°F /
                    {Math.round(upcomingWeatherData.daily.temperature_2m_min[index])}°F
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        ))}
    </Box>
  );
};

export default DailyForecast;
