import React from 'react';
import { Grid, List, ListItemIcon, ListItemText, ListItem, Typography, Box } from '@mui/material';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import GaugeChart from 'react-gauge-chart';
import Utils from './Utils';
import GetCurrentAQI from './GetCurrentAQI';
import Loading from './Loading';
import Error from './Error';

const Gauge = ({ campus }) => {


  const { isLoading, error, data } = GetCurrentAQI(campus);

  if (isLoading) return <Loading />;

  if (error) return <Error message={'An Error occurred loading the Air Quality'} />;

  return (
    <Box className='GridItem' display="flex" flexDirection="column" justifyContent="space-between" height="100%">
      <Typography component='h2' variant='h5' align='left' sx={{ marginTop: '1rem' }}>
        {campus.toUpperCase()} Current Air Quality
      </Typography>
      <Grid container paddingTop={2} flexGrow={1}>
        <Grid item md={8} sm={12} xs={12} style={{ position: 'relative' }}>
          <GaugeChart
            id='aqi_gauge'
            arcPadding={0.0}
            arcWidth={0.40}
            cornerRadius={0}
            colors={['#32CD32', '#FFFF00', '#FFA500', '#FF0000', '#8F3F97', '#7E0023']}
            marginInPercent={0.01}
            needleBaseColor={'#333333'}
            needleColor={'#333333'}
            nrOfLevels={6}
            animate={false}
            hideText={true}
            percent={Utils.getGaugeDialPercent(data.sensor['pm2.5'])}ehs
            sx={{ marginTop: '3rem' }}
            aria-label={`Air quality gauge for ${campus.toUpperCase()}`}
          />
          <Typography sx={{ marginTop: '1rem' }} component='h3' gutterBottom variant='h3'>
            {Utils.calulateAqi(data.sensor['pm2.5'])}
          </Typography>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <Typography sx={{ fontSize: '1.5rem' }}>Air Quality Index</Typography>
          <Grid container>
            <Grid item md={8} sm={6} xs={6}>
              <List disablePadding>
              <ListItem disablePadding sx={{marginTop:'0px', marginBottom:'0px'}}>
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FiberManualRecordRoundedIcon fontSize='medium' style={{ color: 'limegreen' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                  secondaryTypographyProps={{ fontSize: '0.9rem', fontWeight:'bolder' }}
                  primary={'Good'}
                  secondary={'0-50'}
                />
              </ListItem>
              </List>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FiberManualRecordRoundedIcon fontSize='medium' style={{ color: 'yellow' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                  secondaryTypographyProps={{ fontSize: '0.9rem', fontWeight:'bolder' }}
                  primary={'Moderate'}
                  secondary={'51-100'}
                />
              </ListItem>
              </List>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FiberManualRecordRoundedIcon fontSize='medium' style={{ color: 'orange' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                  secondaryTypographyProps={{ fontSize: '0.9rem', fontWeight:'bolder' }}
                  primary={'Unhealthy for Sensitive Groups'}
                  secondary={'101-150'}
                />
              </ListItem>
              </List>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FiberManualRecordRoundedIcon fontSize='medium' style={{ color: 'red' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                  secondaryTypographyProps={{ fontSize: '0.9rem', fontWeight:'bolder' }}
                  primary={'Unhealthy'}
                  secondary={'151-200'}
                />
              </ListItem>
              </List>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FiberManualRecordRoundedIcon fontSize='medium' style={{ color: '#8f3f97' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                  secondaryTypographyProps={{ fontSize: '0.9rem', fontWeight:'bolder' }}
                  primary={'Very Unhealthy'}
                  secondary={'201-250'}
                />
              </ListItem>
              </List>
            </Grid>
            <Grid item md={8} sm={6} xs={6}>
            <List disablePadding>
              <ListItem disablePadding>
                <ListItemIcon style={{ minWidth: 25 }}>
                  <FiberManualRecordRoundedIcon fontSize='medium' style={{ color: '#7e0023' }} />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '1rem', fontWeight: 'bold' }}
                  secondaryTypographyProps={{ fontSize: '0.9rem', fontWeight:'bolder' }}
                  primary={'Very Hazardous'}
                  secondary={'251-300'}
                />
              </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='body1' align='left' sx={{ paddingTop: '6px' }}>
            Updated At {Utils.convertUnixToPacificTime(data['data_time_stamp'])}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Gauge;
