
class Utility {

  static calulateAqi(pm25) {
    let aqi;
    if (pm25 >= 0 && pm25 <= 12.0) {
      aqi = Math.round(((50.0 - 0.0) * (pm25 - 0.0) / (12.0 - 0.0)) + 0.0);
    } else if (pm25 >= 12.1 && pm25 <= 35.4) {
      aqi = Math.round(((100.0 - 51.0) * (pm25 - 12.1) / (35.4 - 12.1)) + 51.0);
    } else if (pm25 >= 35.5 && pm25 <= 55.4) {
      aqi = Math.round(((150.0 - 101.0) * (pm25 - 35.5) / (55.4 - 35.5)) + 101.0);
    } else if (pm25 >= 55.5 && pm25 <= 150.4) {
      aqi = Math.round(((200.0 - 151.0) * (pm25 - 55.5) / (150.4 - 55.5)) + 151.0);
    } else if (pm25 >= 150.5 && pm25 <= 250.4) {
      aqi = Math.round(((300.0 - 201.0) * (pm25 - 150.5) / (250.4 - 150.5)) + 201.0);
    } else if (pm25 >= 250.5 && pm25 <= 350.4) {
      aqi = Math.round(((400.0 - 301.0) * (pm25 - 250.5) / (350.4 - 250.5)) + 301.0);
    } else if (pm25 >= 350.5 && pm25 <= 500.4) {
      aqi = Math.round(((500.0 - 401.0) * (pm25 - 350.5) / (500.4 - 350.5)) + 401.0);
    } else {
      aqi = null; // Invalid PM2.5 value
    }
    return aqi;
  }

  static getGaugeDialPercent(pm25) {
    let prct;
    let aqi = this.calulateAqi(pm25);

    if (aqi === 0) {
      prct = 0;
    } else if (aqi >= 300) {
      prct = 1.0; // Max out at 1.0 for AQI above 300
    } else {
      // Define upper and lower limits for each segment
      const segments = [
        { lowerLimit: 0, upperLimit: 50, percentRange: [0, 0.15] }, // Green segment: 0-50
        { lowerLimit: 51, upperLimit: 100, percentRange: [0.16, 0.33] }, // Yellow segment: 51-100
        { lowerLimit: 101, upperLimit: 150, percentRange: [0.34, 0.50] }, // Orange segment: 101-150
        { lowerLimit: 151, upperLimit: 200, percentRange: [0.51, 0.67] }, // Red segment: 151-200
        { lowerLimit: 201, upperLimit: 250, percentRange: [0.68, 0.85] }, // Purple segment: 201-250
        { lowerLimit: 251, upperLimit: 300, percentRange: [0.86, 1.0] } // Maroon segment: 251-300
      ];

      // Find the corresponding segment and calculate the percentage within that segment
      const segment = segments.find(seg => aqi >= seg.lowerLimit && aqi <= seg.upperLimit);
      const segmentSize = segment.upperLimit - segment.lowerLimit;
      const aqiWithinSegment = aqi - segment.lowerLimit;
      const percentWithinSegment = aqiWithinSegment / segmentSize;
      prct = segment.percentRange[0] + percentWithinSegment * (segment.percentRange[1] - segment.percentRange[0]);
    }
    return prct;
  }

  static getWhoAffected(pm25) {
    let aqi = this.calulateAqi(pm25)
    let whoAffected = null;
    if (aqi >= 0 && aqi <= 50) {
      whoAffected = 'No individuals expected to be affected.';
    } else if (aqi >= 51 && aqi <= 100) {
      whoAffected = 'Unusually sensitive individuals (people with lung and heart disease) may be affected.';
    } else if (aqi >= 101 && aqi <= 150) {
      whoAffected = 'Sensitive groups including people with heart or lung disease, older adults, pregnant women, and children affected.';
    } else if (aqi >= 151 && aqi <= 200) {
      whoAffected = 'Everyone affected.';
    } else if (aqi >= 201 && aqi <= 300) {
      whoAffected = 'Everyone affected.';
    } else if (aqi >= 301) {
      whoAffected = 'Everyone affected.';
    } else {
      whoAffected = 'Not Found.';
    }
    return whoAffected;
  }

  static extractHourlyAqi(responseData) {
    const chartData = responseData.data.map(item => {
      const timestamp = new Date(item[0] * 1000);
      const formattedHour = timestamp.toLocaleTimeString('en-US', {
        hour: 'numeric',
        hour12: true
      });
      return [formattedHour, item[1]];
    });
    chartData.unshift(['Hour', 'AQI']);
    return chartData;
  };

  static convertUnixToPacificTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);

    // Create a DateTimeFormat object with Pacific Time zone
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: 'America/Los_Angeles',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    const formattedDateTime = formatter.format(date);
    return formattedDateTime;
  }

  static formatChartDataTime(time, format) {
    const d = new Date(time);
    let string = '';

    if (format === 'hour') {
      string = d.toLocaleTimeString('en-US', {
        hour: '2-digit'
      }).replace(/^0+/, '');
    } else if (format === 'day') {
      string = d.toLocaleDateString('en-US', {
        weekday: 'short'
      }).toUpperCase();
    } else if (format === 'date') {
      string = d.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
      }).replace(/\/(\d{4})/, '');
    } else if (format === 'month') {
      string = d.toLocaleDateString('en-US', {
        month: 'short'
      }).toUpperCase();
    }
    return string;
  }

  static getAQIColorIndex(pm25) {
    let colorIndex = null;
    let aqi = this.calulateAqi(pm25);
    if (aqi >= 0 && aqi <= 50) {
      colorIndex = '#32CD32';
    } else if (aqi >= 51 && aqi <= 100) {
      colorIndex = '#FFFF00';
    } else if (aqi >= 101 && aqi <= 150) {
      colorIndex = '#FFA500';
    } else if (aqi >= 151 && aqi <= 200) {
      colorIndex = '#FF0000';
    } else if (aqi >= 201 && aqi <= 250) {
      colorIndex = '#8F3F97';
    } else if (aqi >= 251) {
      colorIndex = '#7E0023';
    } else {
      colorIndex = '#32CD32';
    }
    return colorIndex;
  }

  static getCampusCoordinates = (campus) => {
    if (campus === 'upc') {
      return [34.0214, -118.2890]; // USC University Park Campus coordinates
    } else if (campus === 'hsc') {
      return [34.0584, -118.2065]; // USC Health Sciences Campus coordinates
    } else {
      // Default to USC University Park Campus coordinates
      return  [34.0214, -118.2890];
    }
  };

  static getAQIInformation() {
    return [
      {
        'Level_1_Condition': {
          'AQI': '0-50',
          'Immediate_Required_Actions': Utility.getImmediateAqiActions(0),
          'Situational_Awareness_Actions': [
            'Track air-quality metrics real-time using Athletics Perry Weather App.'
          ]
        }
      },
      {
        'Level_2_Condition': {
          'AQI': '51-100',
          'Immediate_Required_Actions': Utility.getImmediateAqiActions(51),
          'Situational_Awareness_Actions': [
            'Track air-quality metrics real-time using Athletics Perry Weather App.'
          ]
        }
      },
      {
        'Level_3_Condition': {
          'AQI': '101-150',
          'Immediate_Required_Actions': Utility.getImmediateAqiActions(101),
          'Situational_Awareness_Actions': [
            'Track air-quality metrics real-time using Athletics Perry Weather App.'
          ]
        }
      },
      {
        'Level_4_Condition': {
          'AQI': '151-200',
          'Immediate_Required_Actions': Utility.getImmediateAqiActions(151),
          'Situational_Awareness_Actions': [
            'Collaborate with local health care agency and emergency departments for readiness'
          ]
        }
      },
      {
        'Level_5_Condition': {
          'AQI': '201-250',
          'Immediate_Required_Actions': Utility.getImmediateAqiActions(201),
          'Situational_Awareness_Actions': [
            'Collaborate with local health care agency and emergency departments for readiness'
          ]
        }
      },
      {
        'Level_6_Condition': {
          'AQI': '251-300',
          'Immediate_Required_Actions': Utility.getImmediateAqiActions(251),
          'Situational_Awareness_Actions': [
            'Activate Core EOC',
            'Brief PSLT'
          ]
        }
      }
    ];
  }

  static getImmediateAqiActions(aqi) {
    let immediateActions = [];
    if (aqi >= 0 && aqi <= 50) {
      immediateActions.push(
      'None'
      );
    } else if (aqi >= 51 && aqi <= 100) {
      immediateActions.push(
        'None'
      );
    } else if (aqi >= 101 && aqi <= 150) {
      immediateActions.push(
        'Collaborate with Athletics following Athletics Inclement Weather, Earthquakes, and Air Quality Guidelines',
        'General prevention education messaging (digital assets for screens and social media) distributed to communication officers and building managers'
      );
    } else if (aqi >= 151 && aqi <= 200) {
      immediateActions.push(
        'Collaborate with Athletics following Athletics Inclement Weather, Earthquakes, and Air Quality Guidelines',
        'General prevention education messaging (digital assets for screens and social media) distributed to communication officers and building managers'
      );
    } else if (aqi >= 201 && aqi <= 250) {
      immediateActions.push(
        'Suspend outdoor activities',
        'Collaborate with health services to address potential respiratory issues',
        'Require masks for outdoor jobs that cannot be moved indoors; offer N95s to campus with instructions',
        'Create detailed  safety measures, air quality metrics, and available resources posting to safety.usc.edu'
      );
    } else if (aqi >= 251) {
      immediateActions.push(
        'Suspend outdoor activities',
        'Implement indoor curfew for campus community',
        'Activate emergency.usc.edu for  continual updates detailing campus-wide safety measures and available resources'
      );
    }

    return immediateActions;
  }

  static getHighHeatConditions() {
    return [
      {
        level: '90ºF-94ºF',
        immediateRequiredActions: Utility.getImmediateHeatActions(90),
        situationalAwarenessActions: [
          'Review and adjust campus activities that might exacerbate heat exposure',
        ],
        actionOwnedBySituationalAwareness: 'EHS and Student Health',
      },
      {
        level: '95ºF-99ºF',
        immediateRequiredActions: Utility.getImmediateHeatActions(95),
        situationalAwarenessActions: [
          'Assess HVAC functionality in indoor spaces',
          'Have medical personnel (FSEP EMTs) on stand-by for heat related illnesses',
          'Monitor planned practice and competition times and venues. Cancel if wet-bulb > 90',
          'Consider closing if Wet-Bulb temperature above 85. Close if > 90',
          'Pre-flight preparations in place for potential power failures with FPM and building managers.',
        ],
        actionOwnedBySituationalAwareness: 'EHS, Health communications',
      },
      {
        level: '100ºF-ºF',
        immediateRequiredActions: Utility.getImmediateHeatActions(105),       
        situationalAwarenessActions: [
          'Activate Core EOC',
          'Brief PSLT',
          'Coordinate with local emergency services for potential heat related crisis',
        ],
        actionOwnedBySituationalAwareness: 'FSEP; Housing, Dave Wright, Health Communications',
      },
    ];
  }


  static getImmediateHeatActions(temperature) {
    let immediateActions = [];
    if (temperature < 89) {
      immediateActions.push(
          'None'
      );
    }

    if (temperature >= 90 && temperature <= 94) {
      immediateActions.push(
          'Provide rest/shade/water; Required to train employees; monitor temp using weather app, etc.; Monitor employees working in warm areas',
          'General prevention education messaging (digital assets for screens and social media) distributed to communication officers and building managers.'
      );
    }

    if (temperature >= 95 && temperature <= 104) {
      immediateActions.push(
          'Monitor employees more closely and frequently',
          'Consider moving activities indoors, consider canceling or rescheduling outdoor activities planned between 10am and 4pm',
          'Modify or cancel outdoor athletics events',
          'Monitor indoor unairconditioned spaces and consider relocating activities',
          'Directed email messaging to supervisors about heat specific safety measures; Directed email with safety & prevention instructions to students; posting to safety.usc.edu'
      );
    }

    if (temperature >= 105) {
      immediateActions.push(
          'Provide cooling centers including sleeping facilities for student in unair-conditioned facilities',
          'Suspend outdoor activities',
          'Activate emergency.usc.edu for  continual updates detailing campus-wide safety measures and available resources',
          'Community-wide email messaging with safety measures, prevention, and operations updates',
          'Stockpile essential supplies, e.g., water and electrolytes'
      );
    }
    
    return immediateActions;
  }
}

export default Utility