import React from 'react';
import { Box } from '@mui/material';
import CurrentWeatherCard from './CurrentWeatherCard';
import GetCurrentWeather from './GetCurrentWeather';
import wind from './Assets/Icons/wind.svg'
import Sunrise from './Assets/Icons/sunrise.svg'
import Sunset from './Assets/Icons/sunset.svg'
import UV from './Assets/Icons/uv.svg'
import Humidity from './Assets/Icons/humidity.svg'
import Loading from './Loading';
import Error from './Error';
import GetHourlyWeather from './GetHourlyForecast';
const CurrentWeather = ({ campus }) => {
  let images = require.context('./Assets/Icons', true);

  // Utility function to convert Unix timestamp to formatted date
  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const { data: currentforecast, isLoading, isError } = GetCurrentWeather(campus);

  const { data: hourlyForecastData } = GetHourlyWeather(campus);
  const currentHourUVIndex = hourlyForecastData?.hourly?.uv_index[0];
  

  if (isLoading) return <Loading />;

  if (isError) return <Error message={'An error occured loading the current forecast'}/>;

  return (
    <div>
    <Box
      display='flex'
      flexWrap='wrap'
      justifyContent='space-between'
      alignItems='stretch' 
    >
      {currentforecast && (
        <>
          <CurrentWeatherCard
            attributeName={'Temperature'}
            attributeValue={Math.round(currentforecast.current.temperature_2m)}
            attributeUnit={'°F'}
            attributeUrl={images(`./${currentforecast.current.is_day}${currentforecast.current.weather_code}.svg`)}       
          />
          <CurrentWeatherCard
            attributeName={'Wind'}
            attributeValue={Math.round(currentforecast.current.wind_speed_10m)}
            attributeUnit={'mph'}
            attributeUrl={wind}
          />
          <CurrentWeatherCard
            attributeName={'Humidity'}
            attributeValue={currentforecast.current.relative_humidity_2m}
            attributeUnit={'%'}
            attributeUrl={Humidity}
          />
          <CurrentWeatherCard
            attributeName={'Sunrise'}
            attributeValue={formatDate(currentforecast.daily.sunrise[0])}
            attributeUnit={''}
            attributeUrl={Sunrise}
          />
          <CurrentWeatherCard
            attributeName={'Sunset'}
            attributeValue={formatDate(currentforecast.daily.sunset[0])}
            attributeUnit={''}
            attributeUrl={Sunset}
          />
          <CurrentWeatherCard
            attributeName={'UV Index'}
            attributeValue={Math.round(currentHourUVIndex)}
            attributeUnit={''}
            attributeUrl={UV}
          />
        </>
      )}
    </Box>
    </div>
  );
};

export default CurrentWeather;
