import React from 'react';
import USCLogo from './USCLogo.jpg';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Header = ({ handleHome, url }) => {
  return (
    <Grid container alignItems="center" spacing={1} sx={{ padding: '5px' }}>
      <Grid item>
        <img
          alt='University of Southern California'
          style={{ height: '80px', cursor: 'pointer' }}
          onClick={() => handleHome(url)}
          src={USCLogo}
        />
      </Grid>
      <Grid item>
        <Typography variant='h1' fontSize={30}>
          Environmental Health & Safety
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;
