import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import DailyForecast from './DailyForecast';
import HourlyForecast from './HourlyForecast';

const Forecast = ({ campus }) => {
  const [value, setValue] = useState('hourly');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='forecast tabs'
          TabIndicatorProps={{ sx: { backgroundColor: '#FFCC00' } }}
          sx={{
            '& .MuiTabs-flexContainer': { justifyContent: 'space-between' },
            '& button': { color: 'black' },
            '& button:focus': { backgroundColor: '#990000', color: 'white' },
            '& .Mui-selected': { backgroundColor: '#990000', color: 'white' }
          }}
        >
          <Tab value='hourly' label={(<Typography sx={{
            fontSize: { xs: '1.4rem', sm: '1.4rem', md: '1.4rem' },
            fontWeight: '500',
          }}>Hourly </Typography>)} />
          <Tab value='daily' label={(<Typography sx={{
            fontSize: { xs: '1.4rem', sm: '1.4rem', md: '1.4rem' },
            fontWeight: '500',
          }}>Daily </Typography>)} />
        </Tabs>
      </Box>
      <Box>
        {value === 'hourly' ? <HourlyForecast campus={campus} /> : <DailyForecast campus={campus} />}
      </Box>
    </>
  );
};

export default Forecast;

